.header {
  padding: $space-1 0;
  background-color: transparent;
  width: 100%;
  z-index: 99;
  position: fixed;
  top: 0;
  transition: $trans;
  @media screen and (max-width: 991px) {
    padding: $space-01 0;
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: $space-1;
    @media screen and (max-width: 991px) {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: $space-1;
    }
  }

  &__burger {
    justify-self: flex-start;

    i {
      font-size: 2rem;
      color: $color-white;
    }

    @media screen and (min-width: 1200px) {
      display: none;
    }
  }

  &__logo {
    img,
    svg {
      display: block;
      width: 150px;
      height: auto;
      transition: $trans;
      @media screen and (max-width: 991px) {
        width: 120px;
        height: auto;
      }
      @media screen and (max-width: 575px) {
        width: 120px;
        height: auto;
        transition: $trans;
      }
    }
  }

  &__nav {
    margin-left: auto;
    @media screen and (max-width: 1199px) {
      display: none;
    }
  }

  &__contact {
    margin-left: auto;
    @media screen and (min-width: 1200px) {
      display: none;
    }
  }

  &__wpml {
    display: flex;
    justify-content: center;
    .wpml-ls-link img {
      width: 26px !important;
      border: 1px solid $color-grey-5;
      border-radius: 4px;
    }
    .wpml-ls-native {
      display: none;
      visibility: hidden;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  &--home {
    background-color: transparent;
  }
  &--sticky {
    padding: calc($font-size-smaller / 2) 0;
    background-color: $color-primary;
    box-shadow: $box-shadow;
    transition: $trans;
    .header__logo {
      img,
      svg {
        width: 100px !important;
        transition: $trans;
        @media screen and (max-width: 991px) {
          width: 120px !important;
          transition: $trans;
        }
        @media screen and (max-width: 575px) {
          width: 100px !important;
          transition: $trans;
        }
      }
    }
  }
}
