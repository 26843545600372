@mixin button {
  padding: 1.0625rem 1.875rem; // cambiar tamaño
  font-size: 1rem;
  line-height: 1;
  border-radius: 36px; // cambiar radius
  font-weight: $font-weight-medium;
  text-align: center;
  cursor: pointer;
  border: 0;
  transition: $trans;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-width: 2px;
  border-style: solid;
  @media screen and (max-width: 767px) {
    padding: 0.8625rem 1.475rem;
    font-size: $font-size-small;
  }
  &--lg {
    padding: 1.125rem 1.9375rem;
    @media screen and (max-width: 767px) {
      padding: 1rem 1.475rem;
    }
  }
  &--sm {
    padding: 0.625rem 1.25em;
    font-size: $font-size-small;
  }
}

@mixin reset-ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

@mixin overlay-deg {
  content: "";
  position: absolute;
  display: block;
  inset: 0;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

@mixin hr-bottom {
  padding-bottom: $space-2;
  margin-bottom: $space-2;
  border-bottom: 1px dashed $color-grey-4;
}

@mixin img-fluid {
  border-radius: $border-radius-min;
  max-width: 100%;
  height: auto;
}
