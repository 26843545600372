.nav-primary {
  &__items {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    & > li.menu-item {
      a {
        color: $color-white;
        font-size: $font-size;
        font-family: $typo-secondary;
        text-decoration: none;
        padding: $font-size-smaller $font-size-small;
        @media screen and (max-width: 1400px) {
          padding-left: 14px;
          padding-right: 14px;
        }
        &:hover {
          color: $color-grey-5;
        }
        @media screen and (max-width: 1320px) {
          font-size: 0.95rem;
          padding-left: $font-size-smaller;
          padding-right: $font-size-smaller;
        }
      }
      &:nth-last-child(1) {
        margin-left: $space-01;
        a {
          @include button;
          padding: 0.7rem 1.2rem;
          font-size: $font-size-small;
          background-color: $color-white;
          border-color: $color-white;
          color: $color-primary;
          &:hover {
            background-color: $color-white;
            border-color: $color-white;
            color: $color-primary;
            box-shadow: $box-shadow-small;
            transform: translateY(-2px);
            transition: $trans;
          }
          @media screen and (max-width: 1010px) {
            padding: 7px 12px;
          }
        }
      }
    }
  }
}

#offcanvasMenu {
  border: none;
}

.offcanvas {
  &-body {
    background-color: $color-dark;
    padding-top: 0.5rem;
    button {
      display: flex;
      margin-right: auto;
      margin-top: 0.5rem;
      border: none;
      background: none;
      font-size: 3rem;
      line-height: 0;

      i {
        color: $color-white;
        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  &__navbar {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
    align-items: center;

    @media screen and (max-width: 575px) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  &__wpml {
    display: flex;
    justify-content: center;
    .wpml-ls-link img {
      width: 32px !important;
      border: 1px solid $color-grey-5;
      border-radius: 4px;
    }
    .wpml-ls-native {
      display: none;
      visibility: hidden;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    img,
    svg {
      display: flex;
      width: 120px;
      margin-top: $space-1;
      transition: $trans;
      @media screen and (max-width: 575px) {
        width: 100px;
        transition: $trans;
      }
    }
  }
}

.nav-contact {
  display: flex;
  gap: $font-size;
  &__item {
    width: 30px;
    height: 30px;
    background: $color-white;
    border-radius: 50px;
    color: $color-dark;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      color: $color-dark;
    }
    &--whatsapp {
      background-color: $color-whatsapp-light;
      i {
      }
    }
  }
}

.nav-offcanvas {
  margin-top: $space-1;
  display: flex;

  &__nav {
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    li.menu-item {
      margin-bottom: $font-size-smaller;
      a {
        font-size: $header-3-res;
        font-weight: $font-weight-bold;
        color: $color-white;
        text-decoration: none;
      }
    }
  }
}
