.bg-dark.p-productos-entry {
  .p-productos-entry__title {
    color: $color-white;
  }

  .p-productos-entry__subtitle {
    color: $color-white;
  }

  .p-productos-entry__description {
    color: $color-grey-5;
  }
}

.p-productos-entry {
  padding: $space-6 0;
  position: relative;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  &__cover {
    position: absolute;
    width: 70%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    @media screen and (max-width: 767px) {
      width: 100%;
      left: 0;
      opacity: 0.2;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center right;
    }
  }
  &__content {
    position: relative;
    @media screen and (min-width: 992px) {
      max-width: 70%;
    }
    &--50 {
      @media screen and (min-width: 992px) {
        max-width: 50%;
      }
    }
  }

  &__title {
    font-size: $header-4;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      font-size: $header-3-res;
    }
  }

  &__subtitle {
    font-size: $header-2;
    margin-bottom: $space-1;
    color: $color-primary;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 767px) {
      font-size: $header-2-res;
    }
  }

  &__description {
    color: $color-grey-3;
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }
}

.p-productos {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.g-productos {
  background-color: $color-grey-6;
  padding: $space-2 0 $space-3;
  &__title {
    font-size: $header-2;
    margin-bottom: $space-2;
    font-weight: $font-weight-bold;
    color: $color-primary;
  }
  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: $space-1 $space-1;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  &__item {
  }

  &__nombre {
    font-weight: $font-weight-medium;
    color: $color-accent;
  }

  &__cientifico {
    font-size: $font-size-small;
    font-style: italic;
  }
}
