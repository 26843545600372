.map {
  iframe {
    width: 100% !important;
    border-radius: $font-size;
  }
}
.pad-page {
  padding-top: $space-3;
  padding-bottom: $space-5;
  @media screen and (max-width: 767px) {
    paddining-bottom: $space-2;
  }
}

.p-contacto {
  display: grid;
  gap: $space-4;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
  }
  &__left {
  }

  &__right {
  }
}

.contactos {
  @include hr-bottom;
  &__titulo {
    font-size: $header-2;
    color: $color-primary;
    font-weight: $font-weight-bold;
    margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-1;
      font-size: $header-2-res;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: $space-2;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $font-size-big;
    }
  }

  &__item {
  }

  &__nombre {
    font-size: $header-4;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size;
    @media screen and (max-width: 991px) {
      margin-bottom: calc($font-size-smaller / 3);
    }
  }

  &__email {
    a {
      color: $color-primary;
      text-decoration: none;
    }
  }

  &__telefono {
    color: $color-grey-3;
    text-decoration: none;
    display: block;
  }
}

.contactos-post {
  @include hr-bottom;
  &__titulo {
    font-size: $header-2;
    color: $color-primary;
    font-weight: $font-weight-bold;
    margin-bottom: $space-2;
    @media screen and (max-width: 767px) {
      margin-bottom: $space-1;
      font-size: $header-2-res;
    }
  }
  &__p {
    margin-bottom: $space-1;
  }
  &__items {
    display: flex;
    width: 100%;
    gap: $space-1;
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }
  }

  &__actions {
    .boton {
      white-space: nowrap;
    }
  }

  &__content {
    width: 100%;
  }

  &__item {
    display: flex;
    span {
      flex: 0 0 50%;
      font-weight: $font-weight-bold;
    }
    a {
      color: $color-primary;
      text-decoration: none;
    }
    &--whatsapp {
      span {
        color: $color-whatsapp-dark;
      }
    }
  }
}
