.boton {
  @include button;
}

$buttons: (
  // nom, color fondo, color texto, color texto-outline
  "primary" $color-primary $color-white $color-grey-2,
  "secondary" $color-secondary $color-white $color-grey-2,
  "accent" $color-accent $color-white $color-accent,
  "white" $color-white $color-primary $color-primary,
  "dark" $color-grey-2 $color-white $color-grey-3,
  "whatsapp" $color-whatsapp-dark $color-white $color-grey-2
);

@each $name, $c-background, $c-text, $c-text-outline in $buttons {
  .boton--#{$name} {
    background: $c-background;
    color: $c-text;
    border-color: $c-background;
    transition: $trans;
    &:hover {
      background: darken($c-background, 5%);
      border-color: darken($c-background, 5%);
      color: $c-text;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }

  .boton--#{$name}-outline {
    // background: $c-background;
    color: $c-text-outline;
    border-color: $c-background;
    transition: $trans;
    &:hover {
      background: darken($c-background, 5%);
      border-color: darken($c-background, 5%);
      color: $c-text;
      box-shadow: $box-shadow-small;
      transform: translateY(-2px);
      transition: $trans;
    }
  }
}
