.footer {
  padding: $space-3 0 $space-5 0;
  background-color: $color-dark;
  // box-shadow: 0 50vh 0 50vh $color-dark;
  position: relative;
  @media screen and (max-width: 767px) {
    padding-bottom: $space-2;
  }
  &__bg {
    z-index: 2;
    position: absolute;
    top: -19px;
    left: 0;
    right: 0;
    overflow: hidden;
    img {
      position: relative;
      height: 20px;
      width: auto;
    }
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: $space-3;
    @media screen and (max-width: 767px) {
      display: flex;
      gap: $space-1;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  &__item {
    color: $color-text-footer;
    p {
      line-height: 1.5;
    }
    a {
      color: $color-text-footer;
      &:hover {
        color: $color-grey-5;
      }
    }
    .widget-footer__title {
      color: $color-text-footer;
      font-size: 22px;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
    }
  }

  &__item-logo {
    margin-bottom: $space-1;
    img,
    svg {
      max-width: 100px;
    }
  }

  &__copy {
  }
}

.widget-footer {
  &__title {
  }
  .textwidget {
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }
}

.widget-footer.zoom-social-icons-widget {
  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0px;
    margin-top: -5px;
    @media screen and (max-width: 767px) {
      margin-left: 0 !important;
      margin-top: 0 !important;
      gap: $space-05;
    }
    li:nth-child(1) {
      .social-icon {
        padding-left: 0 !important;
      }
    }
  }
  .social-icon {
    font-size: 28px !important;
    color: $color-text-footer !important;
    @media screen and (max-width: 800px) {
      font-size: 20px !important;
    }
    &:hover {
      color: $color-grey-5 !important;
    }
  }
}

.copyright {
  padding: 0 0 $space-3 0;
  position: relative;
  background: $color-dark;
  &__content {
    display: flex;
    justify-content: space-between;
    color: $color-white;
    font-size: $font-size-smaller;
    flex-wrap: wrap;
    @media screen and (max-width: 767px) {
      text-align: center;
      justify-content: center;
    }
    a {
      text-decoration: none;
      color: $color-white;
    }
  }
  &__copy {
    @media screen and (max-width: 767px) {
      margin-bottom: 5px;
    }
  }
}

.next-gen {
  padding-bottom: $space-1;

  @media screen and (max-width: 575px) {
    padding-bottom: $space-1;
  }
  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: $space-1 $space-1;
    flex-wrap: wrap;
    padding-top: $space-1;
    border-top: 1px dashed $color-white;
    @media screen and (max-width: 575px) {
      display: flex;
      flex-direction: column;
      gap: calc($font-size / 2);
    }
  }

  &__logos {
    display: flex;
    gap: $space-1;
  }
  &__img {
    img {
      width: 200px;
      height: auto;
      @media screen and (max-width: 575px) {
        width: 100px;
      }
    }
  }

  &__description {
    color: $color-dark;
    font-size: $font-size-small;
    @media screen and (max-width: 575px) {
      text-align: center;
    }
  }
}
