.mision {
  padding: $space-4 0;

  &__cols {
    display: grid;
    gap: $space-4;
    grid-template-columns: 1fr 1fr 1fr;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }

  &__col {
  }

  &__title {
    font-size: $header-2;
    margin-bottom: $space-2;
    font-weight: $font-weight-bold;
    color: $color-primary;
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
      margin-bottom: $font-size;
    }
  }

  &__descripcion {
  }
}

.sostenible {
  background: $color-grey-6;
  padding: $space-4 0;
  &__grid {
  }
}

.sostenible {
  &__container {
  }

  &__header {
  }

  &__titulos {
    display: flex;
    gap: $header-1;
    @media screen and (min-width: 992px) {
      max-width: 70%;
    }
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: $space-1;
    }
  }
  &__titulos-ico {
    img {
      width: 100px;
      height: auto;
    }
  }

  &__title {
    font-size: $header-4;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size;
  }

  &__subtitulo {
    font-size: $header-2;
    font-weight: $font-weight-bold;
    margin-bottom: $font-size;
    color: $color-primary;
    @media screen and (max-width: 991px) {
      font-size: $header-2-res;
    }
  }

  &__descripcion {
    margin-bottom: $space-3;
  }

  &__gallery {
    display: grid;
    gap: $space-2;
    grid-template-columns: 1fr 1fr;
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }

  &__gallery-item {
    img {
      max-width: 100%;
      height: auto;
      border-radius: 5px;
    }
  }
}
