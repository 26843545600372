.page-title {
  background-color: $color-primary;
  color: $color-white;
  padding-top: calc($space-5 + $space-3);
  padding-bottom: $space-2;
  @media screen and (max-width: 767px) {
    padding-top: calc($space-5);
  }

  &__h {
    font-size: $header-5;
    text-transform: uppercase;
    letter-spacing: 1px;
    @media screen and (max-width: 767px) {
      font-size: $header-6;
    }
  }
  &__sh {
    font-size: $header-1;
    margin-bottom: $space-2;
    font-weight: $font-weight-bold;
    @media screen and (min-width: 992px) {
      max-width: 80%;
    }
    @media screen and (max-width: 767px) {
      font-size: $header-2-res;
      margin-bottom: $font-size;
    }
  }
  &__breadcrumbs {
    font-size: $font-size-smaller;
    a {
      text-decoration: none;
      color: $color-white;
    }
    & > span {
      & > span {
        padding-right: 10px;
      }
      .breadcrumb_last {
        padding-left: 10px;
      }
    }
  }
}
