.claim {
  padding: $space-4 0;

  &__content {
  }
}

.destacado {
  padding: $space-3 0;
  @media screen and (max-width: 767px) {
    padding: $space-2 0;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: $space-2;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: $space-1;
    @media screen and (max-width: 767px) {
      display: grid;
      grid-template-columns: 50px 1fr;
      gap: $space-1;
    }
  }
  &__meta {
  }
  &__icono {
    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: flex-end;
    }
    img {
      width: auto;
      height: 66px;
      @media screen and (max-width: 767px) {
        margin-top: calc($font-size / 2);
        width: 50px;
        height: auto;
      }
    }
  }

  &__titulo {
    font-size: $font-size-bigger;
    font-weight: $font-weight-bold;
    color: $color-primary;
    margin-bottom: $font-size;
    @media screen and (max-width: 767px) {
    }
  }

  &__descripcion {
    font-size: $font-size-small;
    color: $color-grey-3;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
  }
}

.bloque-info {
  &--bg-half {
    background: linear-gradient(360deg, $color-dark 50%, $color-background 50%);
  }
  &__content {
  }
}

.stats {
  background-color: $color-dark;
  padding: calc($space-6 + $space-1) 0;
  position: relative;
  @media screen and (max-width: 767px) {
    padding: $space-3 0;
  }
  &__overlay {
    position: absolute;
    background: rgba($color: $color-dark, $alpha: 0.8);
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    transition: $trans;
  }
  &__container {
    position: relative;
    z-index: 2;
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
      gap: $space-2;
    }
  }

  &__info {
    padding-right: $space-3;
    display: flex;
    flex-direction: column;
    gap: $space-2;
  }

  &__logo {
    img {
      width: 100%;
      height: auto;
      max-width: 280px;
    }
  }

  &__descripcion {
    color: $color-white;
  }

  &__actions {
    display: flex;
    justify-content: flex-start;
  }

  &__data {
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: $space-3;
    @media screen and (max-width: 767px) {
      gap: $space-1;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $color-white;
  }

  &__item-titulo {
    font-size: 5rem;
    font-weight: $font-weight-bold;
    line-height: 1;
    @media screen and (max-width: 767px) {
      font-size: $header-1;
    }
  }

  &__item-descripcion {
    font-size: $header-3;
    font-weight: $font-weight-bold;
    @media screen and (max-width: 767px) {
      font-size: $header-5;
    }
  }
}

.galeria-slider {
  padding-top: $space-2;
  &__items {
  }

  &__item {
    padding-left: calc($font-size / 2);
    padding-right: calc($font-size / 2);

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 5px;
      aspect-ratio: 1.5 / 2;
    }
  }
}

.slick-dots {
  bottom: -$space-2 !important;
}

.slick-dark {
  .slick-dots {
    li {
      button {
        &::before {
          color: $color-white !important;
        }
      }
    }
    li.slick-active {
      button {
        &::before {
          color: $color-white !important;
        }
      }
    }
  }
}
