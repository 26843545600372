.feat {
  display: flex;
  flex-direction: column;
  gap: $space-4;

  &__card {
    width: 100%;
    display: flex;
    align-items: center;
    //gap: $space-4;
    @media screen and (max-width: 991px) {
      gap: $space-2;
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      gap: $space-1;
      gap: $font-size;
    }

    &--inverted {
      @media screen and (min-width: 768px) {
        flex-direction: row-reverse;
        .feat__card-content {
          margin-left: 0;
          margin-right: -$space-5;
        }
      }
    }
  }
  &__card-img {
    display: block;
    background-color: $color-white;
    overflow: hidden;
    flex: 0 0 55%;
    @media screen and (max-width: 991px) {
      flex: 0 0 50%;
    }
    @media screen and (max-width: 810px) {
      margin-bottom: $space-01;
    }
    img {
      aspect-ratio: 16 / 11;
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__card-content {
    // width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $color-white;
    width: calc(100% + $space-5);
    padding: $space-3;
    border-radius: 6px;
    margin-left: -$space-5;
    box-shadow: $box-shadow-large;
    transition: $trans;
    max-width: 700px;
    z-index: 1;

    @media screen and (max-width: 991px) {
      padding: $space-1;
      transition: $trans;
    }
    @media screen and (max-width: 767px) {
      width: 94%;
      margin-left: 0;
      margin-top: -8rem;
      transition: $trans;
    }
    @media screen and (max-width: 480px) {
      margin-top: -$space-3;
      transition: $trans;
      padding-left: $font-size;
      padding-right: $font-size;
    }
    &--center {
      align-items: center;
      text-align: center;
    }
  }

  &__card-pretitle {
    text-transform: uppercase;
    font-size: $header-3-res;
    font-weight: $font-weight-bold;
    color: $color-accent;
    font-family: $typo-secondary;
    margin-bottom: $space-05;
    transition: $trans;

    @media screen and (max-width: 767px) {
      display: none;
      transition: $trans;
    }
  }

  &__card-title {
    font-family: $typo-secondary;
    font-size: $header-1;
    font-weight: $font-weight-bold;
    line-height: 1;
    margin-bottom: $font-size;
    color: $color-primary;
    margin-bottom: $space-1;
    transition: $trans;

    @media screen and (max-width: 991px) {
      font-size: $header-1-res;
      transition: $trans;
    }
    @media screen and (max-width: 575px) {
      font-size: $header-2-res;
      transition: $trans;
    }
    a {
      color: $color-black;
      &:hover {
        color: $color-black;
      }
    }
  }

  &__card-description {
    color: $color-text-body;
    line-height: 1.5;
    margin-bottom: $space-1;
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
      margin-bottom: $font-size;
    }
    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      li {
        position: relative;
        padding-left: $space-1;
        &::before {
          position: absolute;
          left: 0;
          top: 3px;
          display: inline-block;
          content: "";
          color: $color-text-body;
          vertical-align: -0.125em;
          background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z' clip-rule='evenodd'/></svg>");
          background-repeat: no-repeat;
          background-size: 1rem 1rem;
          width: 1rem;
          height: 1rem;
          // filter: brightness(0) invert(1);
        }
      }
    }
  }

  &__card-actions {
    margin-top: $space-01;
    display: flex;
    justify-content: flex-start;
  }
}

.fullfeat {
  &__card {
    height: 760px;
    background-color: #ebeae8;
    position: relative;
    overflow: hidden;
    transition: $trans;
    @media screen and (min-width: 1420px) {
      height: 920px;
    }
    @media screen and (max-width: 991px) {
      height: 680px;
      transition: $trans;
    }
    &:nth-child(even) {
      .fullfeat__card-container {
        justify-content: flex-start;
        text-align: left;
      }
    }
  }

  &__card-overlay {
    position: absolute;
    background: rgba($color: #000000, $alpha: 0.1);
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    transition: $trans;

    @media screen and (max-width: 767px) {
      background: rgba($color: #000000, $alpha: 0.6);
      //display: none;
      transition: $trans;
    }
  }

  &__card-img {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    transition: $trans;
  }

  &__card-container {
    position: relative;
    z-index: 2;
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    transition: $trans;
    @media screen and (max-width: 575px) {
      align-items: flex-end;
      transition: $trans;
    }
  }

  &__card-content {
    background-color: rgba($color-black, 0.8);
    border-radius: $border-radius;
    width: max-content;
    padding: $space-1;
    margin-bottom: $space-3;
    transition: $trans;
    max-width: 540px;
    @media screen and (max-width: 991px) {
      padding: $space-1;
      transition: $trans;
    }
  }

  &__card-pretitle {
    text-transform: uppercase;
    font-size: $font-size-big;
    color: $color-white;
    font-family: $typo-secondary;
    margin-bottom: $space-05;
    transition: $trans;
  }

  &__card-title {
    color: $color-white;
    font-family: $typo-secondary;
    font-size: 2rem;
    font-weight: $font-weight-bold;
    line-height: 1;
    margin-bottom: $font-size;
    margin-bottom: $space-1;
    transition: $trans;

    @media screen and (max-width: 575px) {
      font-size: $header-2-res;
      transition: $trans;
    }
  }

  &__card-description {
    color: $color-white;
    border-radius: 3px;
    font-weight: 500;
    margin-bottom: 1rem;
    font-size: $font-size;
    transition: $trans;
  }
}
