.cta {
  padding: $space-5 0 $space-2 0;
  background-repeat: repeat;
  background-size: 400px 400px;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 767px) {
    padding-top: $space-3;
  }
  &__content {
  }

  &__productos {
  }

  &__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%;
    background-color: $color-primary;
    img {
      position: relative;
      top: -19px;
      height: 20px;
    }
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: space-around;
    gap: $space-3;
    @media screen and (max-width: 767px) {
      gap: $font-size;
    }
  }

  &__productos-item {
    aspect-ratio: 1 / 1;
    width: 100%;
    padding: $space-01;
    background-color: $color-white;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: $space-01;
    box-shadow: $box-shadow;
  }

  &__productos-img {
    img {
      width: 100%;
      height: auto;
      max-width: 180px;
      @media screen and (max-width: 767px) {
        max-width: 100px;
      }
      @media screen and (max-width: 575px) {
        max-height: 80px;
        max-width: auto;
      }
    }
  }

  &__productos-titulo {
    font-size: $font-size-bigger;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: $color-primary;
    @media screen and (max-width: 767px) {
      font-size: $font-size-smaller;
    }
  }
}

.cta-info {
  padding: $space-1 0 $space-4 0;
  background-color: $color-primary;
  position: relative;

  &__border {
    transform: rotate(180deg);
    position: absolute;
    bottom: -16px;
    overflow: hidden;
    left: 0;
    right: 0;
    width: 100%;
    line-height: 0;
    direction: ltr;
    z-index: 2;
    svg {
      height: 100px;
      display: block;
      width: 100vw;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      // mix-blend-mode: multiply;
      // filter: invert(1);
      @media screen and (max-width: 575px) {
        width: calc(250% + 1.3px);
      }
    }
  }
}
