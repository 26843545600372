.calidad {
  padding: $space-4 0;

  &__column {
    display: flex;
    flex-direction: column;
    gap: $space-3;
  }

  &__item {
    display: flex;
    gap: $space-3;
    &:nth-child(2n) {
      @media screen and (min-width: 992px) {
        flex-direction: row-reverse;
      }
    }
    @media screen and (max-width: 991px) {
      flex-direction: column;
      gap: $space-1;
    }
  }

  &__cover {
    flex: 0 0 60%;
    img {
      aspect-ratio: 16 / 11;
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: $font-size;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__pretitle {
    font-size: $header-6;
    font-size: $color-grey-3;
    color: $color-accent;
    text-transform: uppercase;
  }

  &__title {
    margin-bottom: $header-2;
    font-weight: $font-weight-bold;
    margin-bottom: $space-1;
    color: $color-primary;
    @media screen and (max-width: 767px) {
      font-size: $header-2-res;
    }
  }

  &__description {
    color: $color-grey-3;
    @media screen and (max-width: 767px) {
      font-size: $font-size-small;
    }
  }
}
